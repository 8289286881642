<template>

    <!-- Dialog com o conteúdo do cartão -->
    <v-dialog v-model="Aboutdialog" max-width="600px">
  <v-card class="pa-6 elevation-4" style="background: #f5f5f5; border-radius: 15px;">
    <v-card-title class="headline text-center" style="color: #3f51b5;">
      <v-icon left>mdi-information-outline</v-icon>
      <strong>Desafio de Questões</strong>
    </v-card-title>
    <v-card-text>
      <p class="text-body-1" style="font-size: 16px; color: #333;">
        <strong>Você tem 150 questões para serem feitas até o dia da prova.</strong>
      </p>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item v-for="(item, index) in [
          'Não é necessário realizar as 150 questões de uma única vez.',
          'Você pode controlar seu ritmo e fazer por partes.',
          'Selecione o número de questões desejado.',
          'Você pode escolher entre o modo tutor ou normal.',
          'Seu progresso será salvo, e não haverá repetição de questões.'
        ]" :key="index">
          <v-list-item-icon>
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-dialog v-model="Aboutdialog" max-width="600px">
  <v-card class="pa-6 elevation-4" style="background: #f5f5f5; border-radius: 15px;">
    <v-card-title class="headline text-center" style="color: #3f51b5;">
      <strong>Desafio de Questões</strong>
    </v-card-title>
    <v-card-text>
      <p class="text-body-1" style="font-size: 16px; color: #333;">
        <strong>Você tem 150 questões para serem feitas até o dia da prova.</strong>
      </p>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item v-for="(item, index) in [
          'Não é necessário realizar todas as 150 questões de uma única vez.',
          'Você pode controlar seu ritmo e fazer por partes.',
          'Selecione o número de questões desejado.',
          'Você pode escolher entre o modo tutor ou normal.',
          'Seu progresso será salvo, e não haverá repetição de questões.'
        ]" :key="index">

          <v-list-item-content>
            <v-list-item-title class="text-body-2" style="word-wrap: break-word; white-space: normal;">
                <v-icon>mdi-information</v-icon>
                {{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="closeSuggestionDialog" color="primary" rounded>Fechar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
            <v-list-item-title class="text-body-2" style="word-wrap: break-word; white-space: normal;">
              {{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="closeSuggestionDialog" color="primary" rounded>Fechar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

    <v-dialog v-model="loading" max-width="200" persistent>
      <v-card elevation="12" class="text-center" color="white">
        <v-card-text>
          <v-progress-circular indeterminate color="primary" size="20" class="pt-10"></v-progress-circular>
          <div class="loading-text">Carregando...</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  
    <!-- Adicione o diálogo para a sugestão Resetar Questoes -->
    <v-dialog v-model="showQuestionResetDialog" max-width="500">
      <v-card>
        <v-card-title>Resetar Questões</v-card-title>
        <v-card-text class="text-justify">
          <!-- Adicione a caixa de texto para a sugestão -->
          Ao resetar, o algoritmo de repetição será reiniciado. As questões já realizadas podem reaparecer. O Reset é recomendado para quem busca repetir questões de imediato. A contagem de questões completas permanecerá inalterada.
        </v-card-text>
        <v-card-actions>
          <!-- Adicione botões para salvar e fechar o diálogo -->
          <v-btn @click="saveQuestionReset" color="primary" text class="ml-auto">Resetar</v-btn>
          <v-btn @click="closeSuggestionDialog" color="red" text>Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <!-- Novo Diálogo para a redefinição -->
    <v-dialog v-model="resetting" max-width="400" persistent>
            <v-card>
              <v-card-title>Salvando dados.</v-card-title>
              <v-card-text>
                <!-- Conteúdo do diálogo de redefinição -->
                Aguarde enquanto seus dados estão sendo salvos...
              </v-card-text>
            </v-card>
          </v-dialog>
  
    <v-container v-if="!quizstart">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
            <!-- Barra de Progresso Abaixo com Porcentagem -->
            <v-progress-linear
                v-model="progress"
                color="teal-lighten-2"
                height="25"
                class="mt-2 mb-2"
                
                >
                <template v-slot:default="{ value }">
                    <strong>Desafio {{ Math.ceil(value) }}% Completo</strong>
                </template>
            </v-progress-linear>

              <v-chip
                class="ma-2"
                color="blue"
                label
                text-color="white"
                v-bind="props"
                v-on="on"
              >
                <v-icon left class="mt-0 mr-2" >mdi-label</v-icon>
                {{ String(noqdName) }} / {{ String(noqName) }} questões respondidas.
              </v-chip>
              <v-chip
                class="ma-2"
                color="green"
                label
                text-color="white"
                v-bind="props"
                v-on="on"
              >
                <v-icon left class="mt-0 mr-2" >mdi-check</v-icon>
                {{ String(noqcorrectName) }} Acertos.
              </v-chip>
              <v-chip
                class="ma-2"
                color="red"
                label
                text-color="white"
                v-bind="props"
                v-on="on"
              >
                <v-icon left class="mt-0 mr-2" >mdi-close</v-icon>
                {{ String(noqwrongName) }} Incorretas.
              </v-chip>

           <v-card elevation="8" class="mt-5">
            <v-card-title
              class="headline text-center font-weight-bold pl-10 pt-10 mb-0"
              style="color: #282828; white-space: normal; word-break: break-word; overflow-wrap: break-word;"
            >
                          Desafio 150 Questões ({{ String(dbName) }}) 
                          <v-btn
                icon
                v-on="on"
                @click="openQuestionResetDialog"
                class="ml-auto mb-3"
                style="
                  padding: 4px;
                  border-radius: 4px;
                  max-width: 30px;
                  max-height: 30px;
                "
              >
                <v-icon color="blue" size="18">mdi-information</v-icon>
              </v-btn>
            </v-card-title>
        
            <v-text-field
              v-model.number="selectedNumberOfQuestions"
              label="Escolha o número de questões do desafio (1-150)."
              type="number"
              min="1"
              max="150"
              density="compact"
              class="pl-10 pr-10 pt-0 mx-auto"
            ></v-text-field>
  
            <!-- <v-select
              v-model="selectedTheme"
              :items="loadThemes"
              label="Escolha o(s) tema(s)"
              multiple
              style="width: 100%;"
              class="pl-10 pr-10 pt-3 mx-auto"
              @update:model-value="handleThemeSelectionChange"
              placeholder="Selecione um tema..."
              chips
              closable-chips
              :menu-props="{ maxHeight: '300px', width: '300px' }"
            ></v-select> -->
  
            <v-select
              v-model="selectedMode"
              :items="['Tutor', 'Normal']"
              label="Escolha o modo"
              class="pl-10 pr-10 pt-3 mx-auto"
            ></v-select>
  
            <v-card-text v-if="selectedMode === 'Tutor'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
              No modo Tutor, as respostas e resoluções são exibidas logo após cada pergunta.
            </v-card-text>
            <v-card-text v-if="selectedMode === 'Normal'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
              No modo Normal, as respostas e resoluções ficam disponíveis apenas no final do simulado.
            </v-card-text>
  
            <!-- <v-card-actions class="d-flex justify-center pb-0">
              <v-btn
              :disabled="noqdName <= 1"
              style="width: 400px; height: 40px; font-size: 14px; color: white; border-radius: 10px; text-transform: none;"
                @click="startReview"
                color="green"
              >    Gabarito - Questões Respondidas 
            </v-btn>
            </v-card-actions> -->
            <v-card-actions class="d-flex justify-center pb-5">
              <v-btn
              :disabled="selectedNumberOfQuestions > 150 || selectedNumberOfQuestions < 1 || selectedTheme === '' || noqdName >= noqName"
              style="width: 400px; height: 40px; font-size: 14px; color: white; border-radius: 10px; text-transform: none;"
                @click="startQuiz"
                color="primary"
              >    {{ noqdName >= noqName? 'Desafio Completo' : 'Iniciar Questões do Desafio' }}
            </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { onBeforeMount, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  
  
  export default {
    name: 'QuizSetup',
    props: {
      noqdName: Number, // Numero total de questoes feitas
      noqName: Number, // Numero total de questoes disponiveis
      loadThemes: Array,
    },
    setup(props, { emit }) {
      const store = useStore(); // Usando a store do Vuex
  
      onBeforeMount(() => {
        // Limpa o localStorage e cookies antes de montar
        localStorage.clear();
        document.cookie = '';
  
        // Despacha a ação para carregar os itens na store
        store.dispatch('loadItemsChallenge');
        
      });
  
      // Variáveis reativas
      const quizstart = ref(false);
      const selectedNumberOfQuestions = ref(1);
      const selectedTheme = ref(['Todos']);
      const selectedMode = ref('Tutor');
      const progress = computed(() => (store.getters.noqName > 0 ? (store.getters.challengenoqdName / store.getters.challengenoqName) * 100 : 0) || 0);
      const loading = computed(() => store.getters.loading);
      const dbName = computed(() => store.getters.challengedbName);
      const noqdName = computed(() => store.getters.challengenoqdName || 0);
      const noqName = computed(() => store.getters.challengenoqName);
      const noqcorrectName = computed(() => store.getters.challengenoqcorrectName || 0);
      const noqwrongName = computed(() => store.getters.challengenoqwrongName || 0);

      const loadThemes = computed(() => {
        const themes = new Set(['Todos']);
        //const items = store.getters.loadedItems; // ou store.getters.loadItems dependendo de onde você pegar os dados
  
        //items.forEach((question) => {
          // Verifica se question.questionTheme não está em branco antes de adicionar ao conjunto
        //  if (question.questionTheme !== '') {
        //    themes.add(question.questionTheme);
        //  }
        //});
  
        return Array.from(themes);
      });
  
  
      // Métodos
      const showQuestionResetDialog = ref(false);
      const Aboutdialog = ref(false)
      const openQuestionResetDialog = () => {
        Aboutdialog.value = true;
        console.log('Abrindo diálogo de reset de questões');
      };
  
      const closeSuggestionDialog = () => {
        Aboutdialog.value = false;
        console.log('Abrindo diálogo de reset de questões');
      };
      const resetting = ref(false);
  
      const saveQuestionReset = () => {
          resetting.value = true;
          console.log('resetando');
          // Lógica para salvar as edições (ex.: enviar para a API, atualizar o Vuex, etc.)
            store.dispatch('saveQuestionReset')
            .then(() => {
              // Aguardar um tempo antes de recarregar os itens
              setTimeout(() => {
                store.dispatch("loadItemsChallenge");
              }, 1000); // 500 milissegundos de delay
              setTimeout(() => {
                resetting.value = false;
              }, 5000); // 500 milissegundos de delay
            })
            .catch(error => {
              console.error("Erro ao salvar item:", error);
            });
          closeSuggestionDialog();
      };
  
      const handleThemeSelectionChange = (newVal) => {
        // Atualiza o valor de selectedTheme
        selectedTheme.value = newVal;
  
          // Se "Todos" estiver selecionado e qualquer outra opção for selecionada, desmarca "Todos"
          if (selectedTheme.value.includes('Todos') && selectedTheme.value.length > 1) {
            const todosIndex = selectedTheme.value.indexOf('Todos');
            selectedTheme.value.splice(todosIndex, 1);
          } 
          // Se todas as opções forem desmarcadas, seleciona "Todos"
          else if (selectedTheme.value.length === 0) {
            selectedTheme.value = ['Todos'];
          }
  
          console.log('Tema selecionado:', selectedTheme.value);
        };
  
      const router = useRouter();
      const startQuiz = async () => {
        // Inicializa filteredItems como um array vazio para podermos adicionar itens
        let filteredItems = [];
  
        // Filter items based on selected theme and number of questions
        store.getters.challengeloadedItems.forEach(item => {
          // If "Todos" is selected, include all items
          if (selectedTheme.value.includes('Todos') && filteredItems.length < selectedNumberOfQuestions.value) {
            filteredItems.push(item);
          } else if (selectedTheme.value.includes(item.questionTheme) && filteredItems.length < selectedNumberOfQuestions.value) {
            filteredItems.push(item);
          }
        });
  
        console.log('Itens filtrados:', filteredItems);
  
        // Emite o evento para iniciar o quiz
        emit('start-quiz', {
          items: filteredItems,
          mode: selectedMode.value,
        });
  
        router.push({
          name: 'QuestionsQuizChallenge',
          query: {
          mode: selectedMode.value,
          items: JSON.stringify(filteredItems) // Convertendo array para string
        },
            });
      };

      const startReview = async () => {
        // Inicializa filteredItems como um array vazio para podermos adicionar itens
        let filteredItems = [];
  
        // Filter items based on selected theme and number of questions
        store.getters.challengeloadedItems.forEach(item => {
          // If "Todos" is selected, include all items
          if (selectedTheme.value.includes('Todos') && filteredItems.length < selectedNumberOfQuestions.value) {
            filteredItems.push(item);
          } else if (selectedTheme.value.includes(item.questionTheme) && filteredItems.length < selectedNumberOfQuestions.value) {
            filteredItems.push(item);
          }
        });
  
        console.log('Itens filtrados:', filteredItems);
  
        // Emite o evento para iniciar o quiz
        emit('start-quiz', {
          items: filteredItems,
          mode: selectedMode.value,
        });
  
        router.push({
          name: 'QuestionsAnswersChallenge',
          query: {
          mode: selectedMode.value,
          items: JSON.stringify(filteredItems) // Convertendo array para string
        },
            });
      };
  
      return {
        quizstart,
        Aboutdialog,
        showQuestionResetDialog,
        selectedNumberOfQuestions,
        selectedTheme,
        selectedMode,
        resetting,
        progress,
        dbName, // Incluindo o dbName computado
        noqdName, // Incluindo o noqdName computado
        noqName, // Incluindo o noqdName computado
        openQuestionResetDialog,
        closeSuggestionDialog,
        saveQuestionReset,
        handleThemeSelectionChange,
        startQuiz,
        startReview,
        loadThemes,
        loading,
        noqwrongName,
        noqcorrectName,
      };
    },
  };
  </script>
  
  <style scoped>
  /* Estilos opcionais */
  </style>