<template>
   <!-- Diálogo do desafio de 150 questões -->
   <v-dialog v-if="String(dbName) === 'TEMFC'" v-model="showChallengeDialog" max-width="800" persistent>
        <v-card class="pa-4">
          <!-- Banner superior com cor vibrante -->
          <v-card-title class="text-center py-4" style="background-color: #008080; color: white; font-weight: bold; font-size: 1.5rem;">
            🚀 Participe do Desafio 150 Questões!
          </v-card-title>

          <!-- Texto informativo com destaque -->
          <v-card-text class="text-center" style="font-size: 1.1rem; line-height: 1.6;">
            <p>Complete o desafio para revisar os principais conteúdos! São 150 questões para uma revisão completa.</p>
          </v-card-text>

          <!-- Ação com botões centralizados -->
          <v-card-actions class="justify-center">
            <v-btn 
              color="primary" 
              class="mx-2" 
              style="color: white; font-weight: bold;" 
              @click="$router.push('/webapp/questionschallenge')">
              🌟 Participar
            </v-btn>
            <v-btn 
              color="secondary" 
              class="mx-2" 
              style="font-weight: bold;" 
              @click="showChallengeDialog = false">
              ❌ Agora Não
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  <v-dialog v-model="loading" max-width="200" persistent>
    <v-card elevation="12" class="text-center" color="white">
      <v-card-text>
        <v-progress-circular indeterminate color="primary" size="20" class="pt-10"></v-progress-circular>
        <div class="loading-text">Carregando...</div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Adicione o diálogo para a sugestão Resetar Questoes -->
  <v-dialog v-model="showQuestionResetDialog" max-width="500">
    <v-card>
      <v-card-title>Resetar Questões</v-card-title>
      <v-card-text class="text-justify">
        <!-- Adicione a caixa de texto para a sugestão -->
        Ao resetar, o algoritmo de repetição será reiniciado. As questões já realizadas podem reaparecer. O Reset é recomendado para quem busca repetir questões de imediato. A contagem de questões completas permanecerá inalterada.
      </v-card-text>
      <v-card-actions>
        <!-- Adicione botões para salvar e fechar o diálogo -->
        <v-btn @click="saveQuestionReset" color="primary" text class="ml-auto">Resetar</v-btn>
        <v-btn @click="closeSuggestionDialog" color="red" text>Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Novo Diálogo para a redefinição -->
  <v-dialog v-model="resetting" max-width="400" persistent>
          <v-card>
            <v-card-title>Salvando dados.</v-card-title>
            <v-card-text>
              <!-- Conteúdo do diálogo de redefinição -->
              Aguarde enquanto seus dados estão sendo salvos...
            </v-card-text>
          </v-card>
        </v-dialog>

  <v-container v-if="!quizstart">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
            <v-chip
              class="ma-2"
              color="blue"
              label
              text-color="white"
              v-bind="props"
              v-on="on"
            >
              <v-icon left>mdi-label</v-icon>
              {{ String(noqdName) }} / {{ String(noqName) }} questões completas.
            </v-chip>
            <v-btn
              icon
              v-on="on"
              @click="openQuestionResetDialog"
              class="ml-auto mb-3"
              style="
                padding: 4px;
                border-radius: 4px;
                max-width: 30px;
                max-height: 30px;
                background-color: rgba(255, 0, 0, 0.1);
              "
            >
              <v-icon color="red" size="18">mdi-file-restore</v-icon>
            </v-btn>


        <v-card elevation="1">
          <v-card-title
            class="headline text-center font-weight-bold pl-10 pt-10 mb-0"
            style="color: #282828; white-space: normal; word-break: break-word; overflow-wrap: break-word;"
          >
            Iniciar Questões {{ String(dbName) }}
          </v-card-title>

          <v-text-field
            v-model.number="selectedNumberOfQuestions"
            label="Escolha o número de questões que gostaria de fazer (1-90)"
            type="number"
            min="1"
            max="90"
            density="compact"
            class="pl-10 pr-10 pt-0 mx-auto"
          ></v-text-field>

          <v-select
            v-model="selectedTheme"
            :items="loadThemes"
            label="Escolha o(s) tema(s)"
            multiple
            style="width: 100%;"
            class="pl-10 pr-10 pt-3 mx-auto"
            @update:model-value="handleThemeSelectionChange"
            placeholder="Selecione um tema..."
            chips
            closable-chips
            :menu-props="{ maxHeight: '300px', width: '300px' }"
          ></v-select>

          <v-select
            v-model="selectedMode"
            :items="['Tutor', 'Normal']"
            label="Escolha o modo"
            class="pl-10 pr-10 pt-3 mx-auto"
          ></v-select>

          <v-card-text v-if="selectedMode === 'Tutor'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
            No modo Tutor, as respostas e resoluções são exibidas logo após cada pergunta.
          </v-card-text>
          <v-card-text v-if="selectedMode === 'Normal'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
            No modo Normal, as respostas e resoluções ficam disponíveis apenas no final do simulado.
          </v-card-text>

          <v-card-actions class="d-flex justify-center pb-5">
            <v-btn
              :disabled="selectedNumberOfQuestions > 90 || selectedNumberOfQuestions < 1 || selectedTheme == ''"
              style="width: 200px; height: 40px; font-size: 14px; color: white; border-radius: 10px; text-transform: none;"
              @click="startQuiz"
              color="primary"
            >Iniciar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { onBeforeMount, computed, ref, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'QuizSetup',
  props: {
    noqdName: Number, // Numero total de questoes feitas
    noqName: Number, // Numero total de questoes disponiveis
    loadThemes: Array,
  },
  setup(props, { emit }) {
    const store = useStore(); // Usando a store do Vuex
    
    // Variáveis reativas
    const showChallengeDialog = ref(false); // Controla a exibição do diálogo do desafio
    const quizstart = ref(false);
    const selectedNumberOfQuestions = ref(1);
    const selectedTheme = ref(['Todos']);
    const selectedMode = ref('Tutor');

    // Computed para acessar o valor de dbName da store Vuex
    const loading = computed(() => store.getters.loading);
    const dbName = computed(() => store.getters.dbName);
    const noqdName = computed(() => store.getters.noqdName);
    const noqName = computed(() => store.getters.noqName);
    const loadThemes = computed(() => {
      const themes = new Set(['Todos']);
      const items = store.getters.loadedItems; // ou store.getters.loadItems dependendo de onde você pegar os dados

      items.forEach((question) => {
        // Verifica se question.questionTheme não está em branco antes de adicionar ao conjunto
        if (question.questionTheme !== '') {
          themes.add(question.questionTheme);
        }
      });

      return Array.from(themes);
    });

    // Watch para dbName, aguardando seu valor antes de abrir o diálogo
    watch(dbName, (newDbName) => {
      if (newDbName && String(newDbName) === 'TEMFC') {
        showChallengeDialog.value = true;
      }
    });

    // Ação de carregar os itens antes de montar
    onBeforeMount(() => {
      // Limpa o localStorage e cookies antes de montar
      localStorage.clear();
      document.cookie = '';

      // Chama a ação de carregar os itens
      store.dispatch('loadItems');
  
      // Verifica se o dbName é 'TEMFC' e exibe o diálogo
      if (String(dbName.value) === 'TEMFC') {
        showChallengeDialog.value = true;
      }
    });

    // Métodos
    const showQuestionResetDialog = ref(false);

    const openQuestionResetDialog = () => {
      showQuestionResetDialog.value = true;
      console.log('Abrindo diálogo de reset de questões');
    };

    const closeSuggestionDialog = () => {
      showQuestionResetDialog.value = false;
      console.log('Abrindo diálogo de reset de questões');
    };
    const resetting = ref(false);

    const saveQuestionReset = () => {
        resetting.value = true;
        console.log('resetando');
        // Lógica para salvar as edições (ex.: enviar para a API, atualizar o Vuex, etc.)
          store.dispatch('saveQuestionReset')
          .then(() => {
            // Aguardar um tempo antes de recarregar os itens
            setTimeout(() => {
              store.dispatch("loadItems");
            }, 1000); // 500 milissegundos de delay
            setTimeout(() => {
              resetting.value = false;
            }, 5000); // 500 milissegundos de delay
          })
          .catch(error => {
            console.error("Erro ao salvar item:", error);
          });
        closeSuggestionDialog();
    };

    const handleThemeSelectionChange = (newVal) => {
      // Atualiza o valor de selectedTheme
      selectedTheme.value = newVal;

        // Se "Todos" estiver selecionado e qualquer outra opção for selecionada, desmarca "Todos"
        if (selectedTheme.value.includes('Todos') && selectedTheme.value.length > 1) {
          const todosIndex = selectedTheme.value.indexOf('Todos');
          selectedTheme.value.splice(todosIndex, 1);
        } 
        // Se todas as opções forem desmarcadas, seleciona "Todos"
        else if (selectedTheme.value.length === 0) {
          selectedTheme.value = ['Todos'];
        }

        console.log('Tema selecionado:', selectedTheme.value);
      };

    const router = useRouter();
    const startQuiz = async () => {
      // Inicializa filteredItems como um array vazio para podermos adicionar itens
      let filteredItems = [];

      // Filter items based on selected theme and number of questions
      store.getters.loadedItems.forEach(item => {
        // If "Todos" is selected, include all items
        if (selectedTheme.value.includes('Todos') && filteredItems.length < selectedNumberOfQuestions.value) {
          filteredItems.push(item);
        } else if (selectedTheme.value.includes(item.questionTheme) && filteredItems.length < selectedNumberOfQuestions.value) {
          filteredItems.push(item);
        }
      });

      console.log('Itens filtrados:', filteredItems);

      // Emite o evento para iniciar o quiz
      emit('start-quiz', {
        items: filteredItems,
        mode: selectedMode.value,
      });

      router.push({
        name: 'QuestionsQuiz',
        query: {
        mode: selectedMode.value,
        items: JSON.stringify(filteredItems) // Convertendo array para string
      },
          });
    };

    return {
      quizstart,
      showChallengeDialog, // Variável para exibir o diálogo
      showQuestionResetDialog,
      selectedNumberOfQuestions,
      selectedTheme,
      selectedMode,
      resetting,
      dbName, // Incluindo o dbName computadof
      noqdName, // Incluindo o noqdName computado
      noqName, // Incluindo o noqdName computado
      openQuestionResetDialog,
      closeSuggestionDialog,
      saveQuestionReset,
      handleThemeSelectionChange,
      startQuiz,
      loadThemes,
      loading,
    };
  },
};
</script>


<style scoped>
/* Estilos opcionais */
</style>