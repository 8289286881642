import { createStore } from 'vuex';
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail, onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth, db} from '../main'; // importando auth do seu main.js
import { collection, query, where, getDocs, updateDoc, getDoc, doc, limit } from 'firebase/firestore';


import axios from 'axios';

const TABLE_URL = 'https://script.google.com/macros/s/AKfycbz2xew0Ie2dSXvXLXToc5oHE0icdWUdAoM9_YPlk_crczQ-IX3YJSfHT0RYPaMT9q1aaA/exec'
const TABLE_URL_FLASHCARDS = 'https://script.google.com/macros/s/AKfycbxSHd4bzoraHc6I24vqGdD0-peHOitLYPGMbAOpGUeu_SAh2MJNy0bskYaTdpGfg464FA/exec'

export default createStore({
  state: {
    user: null,
    loading: false,
    error: false,
    userName: [],
    areas:[],
    flashcards:[],
    loadedItems:[], 
    challengeloadedItems:[],
    loadedRawQuestions: [],
    loadedGabarito:[],
    admin: "", 
    dbName: "",
    challengedbName: "",
    noqName:"",
    challengenoqName:"",
    noqdName:"",
    challengenoqdName:"", 
    challengenoqcorrectName: "",
    challengenoqwrongName:"",  
    loadedResults:[],
    loadedCards:[],
  },
  getters: {
    user(state) {
      return state.user;
    },
    admin(state){
      return state.admin
    },
    loading(state) {
      return state.loading;
    },
    error(state) {
      return state.error;
    },
    userName (state){
      return state.userName
    },
    areas (state){
    return state.areas
    },
    flashcards(state){
    return state.flashcards
    },
    loadedItems (state) {
      return state.loadedItems
    },
    challengeloadedItems(state) {
      return state.challengeloadedItems
    },
    loadedRawQuestions (state) {
      return state.loadedRawQuestions
    },

    loadedGabarito (state) {
      return state.loadedGabarito
    },
    dbName (state) {
      return state.dbName
    },
    challengedbName (state){
      return state.challengedbName
    },
    noqName (state) {
      return state.noqName
    },
    challengenoqName (state) {
      return state.challengenoqName
    },
    noqdName (state) {
      return state.noqdName
    },
    challengenoqdName (state) {
      return state.challengenoqdName
    },
    challengenoqcorrectName(state){
      return state.challengenoqcorrectName

    },
    challengenoqwrongName(state){
      return state.challengenoqwrongName
    },
    loadedResults (state) {
      return state.loadedResults
    },
    loadedCards (state) {
      return state.loadedCards
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setAdmin(state, payload){
      state.admin = payload
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      if(payload.code == "auth/wrong-password"){
        payload.message = "Senha Incorreta."
      } else if(payload.code == "auth/network-request-failed"){
        payload.message = "Sem conexão."
      } else if(payload.code == "auth/user-not-found"){
        payload.message = "Usuário não encontrado."
      } else if(payload.code == "auth/too-many-requests"){
        payload.message = "Muitas tentativas incorretas. O acesso foi bloqueado temporariamente. Aguarde e tente novamente"
      } else if(payload.code == "auth/invalid-credential"){
        payload.message = "Verifique E-mail ou Senha"
      } else if(payload.code == "auth/invalid-login-credential"){
        payload.message = "Verifique E-mail ou Senha"
      } else {
        payload.message = "Verifique E-mail ou Senha"
      } 
      state.error = payload   
    },
    setUserName(state, payload){
      state.userName = payload
    },
    clearUserName (state){
      state.userName = ""
    },
    setAreas(state, payload){
      state.areas = payload
    }, 
    clearAreas(state){
      state.areas = []
    },   
    setFlashcards(state, payload){
      state.flashcards = payload
    },    
    clearFlashcards(state){
      state.flashcards = []
    },  
    clearError(state) {
      state.error = false;
    },
    setLoadedResults(state,payload){
      state.loadedResults = payload
    },
    setLoadedItems(state, payload){
      state.loadedItems = payload
    },
    challengesetLoadedItems(state, payload){
      state.challengeloadedItems = payload
    },
    setLoadedRawQuestions(state, payload){
      state.loadedRawQuestions = payload
    },
    setLoadedGabarito(state, payload){
      state.loadedGabarito = payload
    },
    setDbName(state, payload){
      state.dbName = payload
    },
    challengesetDbName(state, payload){
      state.challengedbName = payload
    },
    setNoqName(state, payload){
      state.noqName = payload
    },
    challengesetNoqName(state, payload){
      state.challengenoqName = payload
    },
    setNoqdName(state, payload){
      state.noqdName = payload
    },
    challengesetNoqdName(state, payload){
      state.challengenoqdName = payload
    },
    challengesetNoqcorrectName(state, payload){
      state.challengenoqcorrectName = payload
    },
    challengesetNoqwrongName(state, payload){
      state.challengenoqwrongName = payload
    },
    setLoadedCards(state, payload){
      state.loadedCards = payload
    },
    clearLoadedItems(state){
      state.loadedItems = []
    },
    clearLoadedRawQuestions(state){
      state.loadedRawQuestions = []
    },
    clearLoadedGabarito(state){
      state.loadedGabarito = []
    },
    clearloadedResults(state){
      state.loadedResults = []
    },
    cleardbName (state){
      state.dbName = null
    },
    clearnoqName (state){
      state.noqName = null
    },
    clearnoqdName (state){
      state.noqdName = null
    }
  },
  actions: {

    // Ação para revalidar o token
    async revalidateToken({ commit, state }) {
      //const auth = getAuth(); // obter instância do Firebase Auth
      // Verifique se o usuário está logado
      const user = auth.currentUser;
      if (user) {
        try {
          // Tente obter o token do usuário
          const idToken = await getIdToken(user, true); // 'true' força a revalidação do token

          // Se o token for válido, continue
          console.log('Token validado com sucesso:', idToken);
          //return idToken;
        } catch (error) {
          commit('setLoading', false)

          console.error('Erro ao validar o token:', error);
          // Realiza o logout no caso de falha na validação
         // commit('setUser', null); // Limpa o estado do usuário
        //  signOut(auth); // Faz o logout no Firebase
          throw new Error('Erro ao validar o token'); // Lança um erro para ser tratado no guard
              
        }
      } else {
        commit('setLoading', false)

        console.log('Nenhum usuário logado');
        //commit('setUser', null); // Limpa o estado do usuário
        throw new Error('Nenhum usuário logado'); // Lança um erro para ser tratado no guard
        //commit('setUser', null); // Remover usuário do estado caso não esteja logado
      }
    },
    // Suas ações Vuex aqui
    async signUserIn({ commit }, { email, password }) {
      try {
       // console.log( auth );
          
        // Autentique o usuário com e-mail e senha
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        
        // Extraia o usuário da credencial
        const user = userCredential.user;

        // Chame a mutação setUser para atualizar o estado do usuário
        console.log(user)
        commit('setUser', user);
        commit('clearError')
        // Retorne o usuário autenticado para outros fins, se necessário
        return user;
      } catch (error) {
        // Manipule os erros, se ocorrerem
        console.error('Erro ao fazer login:', error.message);
        commit('setLoading', false)
        commit('setError', error)
      }
    },
    
    logout({ commit }) {
      try {
        // Autentique o usuário com e-mail e senha
         signOut(auth);        
        // Extraia o usuário da credencial
        // Chame a mutação setUser para atualizar o estado do usuário
        //commit('setUser', user);
        commit('setUser', null);
        commit('setLoading', false);
        console.log("Logout executado");
        
      } catch (error) {
        // Manipule os erros, se ocorrerem
        console.error('Erro ao fazer logout:', error.message);
        throw error;
      }
    },

    async forgotPassword({ commit }, payload) {
      try {
        //console.log( auth );        
        // Autentique o usuário com e-mail e senha
        const userCredential = await sendPasswordResetEmail(auth, payload.email);
        console.log("Email enviado.")
        commit('clearError')
        // Retorne o usuário autenticado para outros fins, se necessário
      } catch (error) {
        // Manipule os erros, se ocorrerem
        console.error('Erro ao mudar a senha:', error.message);
        commit('setLoading', false)
        commit('setError', error)
      }
    },

    autoSignIn({ commit }, payload) {
      if (payload) {
        commit('setUser', { email: payload.email });
      }
    },

    async listenToAuthChanges({ commit }) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          commit('setUser', { email: user.email });
        } else {
          commit('setUser', null);
        }
      });
    },

    //action to see users name
    async checkExistingUser({ commit, state }) {
      // Verifique se já temos o usuário salvo no estado antes de fazer a consulta
      if (state.userData) {
        console.log('Usuário já está carregado:', state.userData);
        return;
      }
      try {
        console.log(auth.currentUser.email)
        const usersRef = collection(db, 'users'); // Referência para a coleção 'users'
        const q = query(usersRef, where('email', '==', auth.currentUser.email)); // Consulta para buscar usuários com o email especificado
        const querySnapshot = await getDocs(q); // Obtém todos os documentos que correspondem à consulta
        const userDataArray = []; // Array para armazenar os dados dos usuários
        querySnapshot.forEach((doc) => {
          // Para cada documento, imprime apenas o nome do usuário
          console.log('Nome do usuário:', doc.data().name);
          userDataArray.push({firstName: doc.data().name, 
            lastName: doc.data().lastName,
            userEmail: doc.data().email,
            userPhone: doc.data().phone,
            userAge: doc.data().age,
            userGender: doc.data().gender,
            totalCards: doc.data().totalCards,
            dates: doc.data().dates,
            demo: doc.data().demo,
            difficultyTracking: doc.data().difficultyTracking,

             });
          console.log(userDataArray)
          commit('setUserName',userDataArray)
        });
      } catch (error) {
        console.error('Erro ao buscar usuários:', error.message);
        throw error;
      }
    
    },

    // Action to SAVE Profile data.
    async saveProfile ({commit}, payload){
      try {
        // Adicione email e ação ao payload, se necessário
        payload.email = auth.currentUser.email;
        // Referência para a coleção 'users'
        const usersRef = collection(db, 'users');
        console.log(payload.email)

        // Consulta para buscar usuários com o email especificado
        const q = query(usersRef, where('email', '==', payload.email));
    
        // Obtenha os dados do usuário
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          // Atualize os campos do usuário com os dados do payload
          await updateDoc(doc.ref, {
            name: payload.name,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            age: payload.age,
            gender: payload.gender
            // Adicione mais campos conforme necessário
          });
          const userDataArray = []; // Array para armazenar os dados dos usuários

          userDataArray.push({
            firstName: payload.name, 
            lastName: payload.lastName,
            userEmail: payload.email,
            userPhone: payload.phone,
            userAge: payload.age,
            userGender: payload.gender,
             });
          console.log(userDataArray)
          commit('setUserName',userDataArray)
        });
        // commit('profileSaved', payload);
      } catch (error) {
        console.error('Erro ao salvar perfil:', error);
        // Realize uma ação de tratamento de erro adequada, se necessário
      }
    },

     // Action to SAVE CARDS TO USERS data.
     async saveCards ({commit}, payload){
      try {
        // Adicione email e ação ao payload, se necessário
        payload.email = auth.currentUser.email;
        // Referência para a coleção 'users'
        const usersRef = collection(db, 'users');
        console.log(payload.email)
        //pega dado do vuex
        const userDataArray = this.state.userName; // Array para armazenar os dados dos usuários

        const currentDate = new Date();
        // Extract day, month, and year from the current date
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        const currentDateStr = day + "/" + month + "/" + year;
        //const currentDateStr = "20" + "/" + month + "/" + year;

        console.log("Procurando por data:", currentDateStr);
        console.log(userDataArray[0]);

        // Verifica se a data atual está presente no array de datas
        let found = false;
        if (userDataArray.length > 0 && userDataArray[0].dates && Array.isArray(userDataArray[0].dates)) {
          let foundIndex = -1;
          userDataArray[0].dates.forEach((dateObj, index) => {
              if (dateObj.date === currentDateStr) {
                  console.log("Encontrado");
                  foundIndex = index;
                  dateObj.cards += payload.total; // Atualiza o valor de cards
                  return; // Sai do loop forEach
              }
          });

          if (foundIndex !== -1) {
              // Se encontrou a data, atualiza apenas o objeto de data encontrado
              payload.dates = [...userDataArray[0].dates]; // Clona o array de datas
          } else {
              console.log("Não encontrado");
              // Se não encontrou, adiciona uma nova entrada ao array de datas
              payload.dates = [
                  ...userDataArray[0].dates,
                  {
                      date: day + "/" + month + "/" + year,
                      //date: "20" + "/" + month + "/" + year,
                      cards: payload.total
                  }
              ];
            }
        }
        else{
          console.log("userDataArray nao encontrado")
          const date = [] //cria o array.
          date.push({
            date: day+"/"+month+"/"+year, 
            cards: payload.total,
            });
          payload.dates = date;
          console.log("test",payload.dates)
          userDataArray[0].dates = date
          
        }

        // Check if userDataArray exists and userDataArray[0].totalCards is undefined
        if (userDataArray.length > 0 && userDataArray[0].totalCards === undefined) {
          console.log("userDataArray[0].totalCards is undefined");
          userDataArray[0].totalCards = 0
        } else {
          console.log("userDataArray[0].totalCards is defined");
            // Now you can proceed with your code logic
        }
        // Consulta para buscar usuários com o email especificado
        const q = query(usersRef, where('email', '==', payload.email));
            
        // Obtenha os dados do usuário
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          const currentData = doc.data();

          // Atualizar difficultyTracking
          let updatedDifficultyTracking = [...(currentData.difficultyTracking || [])];

          if (Array.isArray(payload.difficultyTracking)) {
            payload.difficultyTracking.forEach((newItem) => {
                const existingIndex = updatedDifficultyTracking.findIndex(item => item.refId === newItem.refId);

                if (existingIndex !== -1) {
                    // Atualizar os campos do item existente
                    updatedDifficultyTracking[existingIndex] = {
                        ...updatedDifficultyTracking[existingIndex],
                        ...newItem // Sobrescreve apenas os campos que estão no newItem
                    };
                } else {
                    // Adicionar o novo item se ele não existir
                    updatedDifficultyTracking.push(newItem);
                }
            });
        }

          // Atualize os campos do usuário com os dados do payload
          await updateDoc(doc.ref, {
            totalCards:  userDataArray[0].totalCards + payload.total ,
            dates: payload.dates,
            difficultyTracking: updatedDifficultyTracking, // Adiciona o campo aqui

            // Adicione mais campos conforme necessário
          });
         // console.log(userDataArray)      
          userDataArray[0].totalCards = userDataArray[0].totalCards + payload.total
          userDataArray[0].difficultyTracking = updatedDifficultyTracking; // Atualiza o estado local

          console.log("debugging.")
          console.log(userDataArray)
          commit('setUserName',userDataArray)
        });
        // commit('profileSaved', payload);
      } catch (error) {
        console.error('Erro ao salvar perfil:', error);
        // Realize uma ação de tratamento de erro adequada, se necessário
      }
    },

    async saveDifficulty({ commit }, payload) { //revisar tudoooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
      console.log('executando......')
      try {
        // Referência para a subcoleção "GO15" dentro da coleção "flashcards/decks"
        const go15SubcollectionRef = collection(db, 'flashcards', 'decks', 'GO15');
    
        // Verificando se há documentos dentro da subcoleção "GO15"
        const go15SubcollectionSnapshot = await getDocs(go15SubcollectionRef);
        if (!go15SubcollectionSnapshot.empty) {
          console.log('Existem documentos dentro da subcoleção GO15.');
          // Se houver documentos, significa que há uma subcoleção dentro do documento GO15.
        } else {
          console.log('Não existem documentos dentro da subcoleção GO15.');
          // Se não houver documentos, significa que não há subcoleção dentro do documento GO15.
        }
      } catch (error) {
        console.error('Erro ao salvar os cards:', error);
        // Tratar o erro conforme necessário
      }
    },




    //action to get Cards Theme
    async getCards({ commit, state }) {
      console.log("lendo cards...")
      if (!state.areas || state.areas.length === 0) {
        commit('setLoading', true); // Only set loading if loadedItems is empty
      }
      // Adicione variáveis para manter as contagens das propriedades
      try {
        if (auth.currentUser) {
          const userEmail = auth.currentUser.email;
          console.log(userEmail);

          // Referência para o documento 'decks' dentro da coleção 'flashcards'
          const decksDocRef = doc(db, 'flashcards_TEMFC', 'decks');

          // Obtenha o documento 'decks'
          const decksDocSnap = await getDoc(decksDocRef);

          let areas = [];

          // Verifique se o documento 'decks' existe
          if (decksDocSnap.exists()) {
            // Lista de subcoleções a serem verificadas
            const subcollections = ['SuperIntensivo'];

            // Itere sobre as subcoleções
            for (const subcollection of subcollections) {
              // Referência para a subcoleção atual dentro do documento 'decks'
              const subcollectionRef = collection(decksDocRef, subcollection);

              // Obtenha os documentos dentro da subcoleção atual
              const docsSnap = await getDocs(subcollectionRef);

              // Itere sobre os documentos
              for (const docSnap of docsSnap.docs) {
                // Acesse cada documento
                const docData = docSnap.data();
                
                // Verifique se a propriedade 'area' existe no documento
                if (docData.area) {
                  let area = areas.find(item => item.nome === docData.area);

                  if (!area) {
                    area = {
                      nome: docData.area,
                      expanded: false,
                      subareas: [],
                      count: 0 // Inicialize a contagem para a área
                    };
                    areas.push(area);
                  }

                  let subarea = area.subareas.find(item => item.nome === docData.subArea);

                  if (!subarea) {
                    subarea = {
                      nome: docData.subArea,
                      expanded: false,
                      temas: [],
                      count: 0 // Inicialize a contagem para a subárea
                    };
                    area.subareas.push(subarea);
                  }

                  let tema = subarea.temas.find(item => item.nome === docData.theme);

                  if (!tema) {
                    tema = {
                      nome: docData.theme,
                      expanded: false,
                      subtemas: [],
                      count: 0 // Inicialize a contagem para o tema
                    };
                    subarea.temas.push(tema);
                  }

                  // Verifique se a propriedade 'subTheme' existe no documento
                  if (docData.subTheme) {
                    let subtema = tema.subtemas.find(item => item.nome === docData.subTheme);

                    if (!subtema) {
                      subtema = {
                        nome: docData.subTheme,
                        count: 0 // Inicialize a contagem para o subtema
                      };
                      tema.subtemas.push(subtema);
                    }
                    // Incrementar a contagem para o subtema
                    subtema.count++;
                  }

                  // Incrementar a contagem para cada propriedade encontrada
                  area.count++;
                  subarea.count++;
                  tema.count++;
                }
              }
            }
          } else {
            console.log('Documento "decks" não encontrado');
          }

          // Exiba as áreas encontradas
          //console.log('Áreas encontradas:', areas);

          // Atualize o estado Vuex com as áreas e as contagens
          commit('setAreas', areas);
          commit('setLoading', false);
        } else {
          console.log('Usuário não autenticado');
        }
      } catch (error) {
        console.error('Erro ao buscar as áreas:', error.message);
        throw error;
      }
    },

    // Função para embaralhar um array usando o algoritmo de Fisher-Yates
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    //Action for generate Cards to the quiz.
    async generateCards({ commit, state}, payload) {
      try {
            const deckDocRef = doc(db, 'flashcards_TEMFC', 'decks');
            let querySnapshot;  
            const cards = [];

            const subcollections = ['SuperIntensivo'];

            for (const subcollection of subcollections) {
              if (payload.subArea) {
                console.log("subarea")
                if(payload.tema){
                  console.log("tema")
                  if(payload.subTema){
                    console.log("subtema")
                    querySnapshot = await getDocs(
                      query(collection(deckDocRef, subcollection),
                            where('area', '==', payload.area),
                            where('subArea', '==', payload.subArea),
                            where('theme', '==', payload.tema),
                            where('subTheme', '==', payload.subTema)
                      )                    
                  );
                  }else{
                    querySnapshot = await getDocs(
                        query(collection(deckDocRef, subcollection),
                              where('area', '==', payload.area),
                              where('subArea', '==', payload.subArea),
                              where('theme', '==', payload.tema)
                        )
                    );
                  }
                }

                else {
                  querySnapshot = await getDocs(
                    query(collection(deckDocRef, subcollection),
                          where('area', '==', payload.area),
                          where('subArea', '==', payload.subArea)
                    )
                  );
                }
            }
            else {
              console.log("area")

                  querySnapshot = await getDocs(
                      query(collection(deckDocRef, subcollection),
                            where('area', '==', payload.area)
                      )
                  );
            }
            //const difficultyTracking = state.userName.difficultyTracking;
              querySnapshot.forEach(doc => {
              const data = doc.data();
              // Verifique se difficultyTracking existe no state.userName[0]
               const difficultyTracking = state.userName[0]?.difficultyTracking || [];
              // Aqui buscamos o easiness no state.userName -> difficultyTracking
              const userDifficulty = difficultyTracking.find(item => item.refId === data.refId);
              
              // Se não encontrar, definimos easiness como 2.5
              const easiness = userDifficulty ? userDifficulty.easiness : 2.5;
              //const userDifficulty = difficultyTracking.find(item => item.refId === data.refId);
              //const easiness = userDifficulty ? userDifficulty.easiness : 2.5; // Se não encontrado, 2.5 padrão

              cards.push({
                question: data.question,
                answer: data.answer,
                questionImage: data.questionImage || null,
                answerImage: data.answerImage || null,
                refId: data.refId,
                easiness: easiness,  // Atribua o easiness correto aqui

              });
            });
          }




          
          // Função para embaralhar um array usando o algoritmo de Fisher-Yates
            function shuffleArray(array) {
              for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
              }
              return array;
            }
          // Ordenar os cartões pelo valor de easiness (do mais difícil para o mais fácil)
          const sortedByDifficulty = [...cards].sort((a, b) => (a.easiness || Infinity) - (b.easiness || Infinity));

          // Selecionar os 5 cartões mais difíceis
          const mostDifficultCards = sortedByDifficulty.slice(0, 5);

          // Remover os 12 cartões mais difíceis do array original
          const remainingCards = cards.filter(card => !mostDifficultCards.includes(card));

          // Embaralhar o restante dos cartões
          const shuffledRemainingCards = shuffleArray(remainingCards);

          // Selecionar 20 cartões aleatórios do restante
          const randomCards = shuffledRemainingCards.slice(0, 20);

          // Combinar os 12 mais difíceis e os 13 aleatórios
          const finalCards = [...mostDifficultCards, ...randomCards];

          // Embaralhar o array combinado para misturar a ordem
          const finalShuffledCards = shuffleArray(finalCards);

          // Atualizar o estado Vuex com os cartões finais
          commit('setFlashcards', finalShuffledCards);

          // Log para debug
          console.log("Cartões selecionados:");
          console.log(finalShuffledCards);

            
            // Aqui você pode fazer o que quiser com os cards, como passá-los para o estado Vuex ou qualquer outra coisa
      } catch (error) {
          console.error('Erro ao buscar os cards:', error.message);
      }
  },

    //Action for cleaning the error state.
    clearError ({commit}) {
      commit('clearError')
    },
    // Action for loading items from Google Sheets database
    async checkAdmin({commit}){
      commit('setLoading', true)  
        // Use axios to make a get request
        const  payload = {}
        payload.email = auth.currentUser.email
        let response = await axios.get(TABLE_URL+'?action=checkAdmin',{params: payload},{
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }})
        // Test response for error states and proceed to filter items on client side
        if(response.status < 400){
            // let filteredItems = []
            // filteredItems = response.data.items   
            // filteredItems.db = response.data.db  
            // var db =  response.data.db  
            // var noq = response.data.noq
            // var noqd = response.data.noqd     
            // // Save data loaded from server
            // commit('setLoadedItems', filteredItems.reverse())
            // commit('setDbName', db)
            // commit('setNoqName', noq)
            // commit('setNoqdName', noqd)
            commit('setAdmin', response.data)
            console.log(response.data)
        } else{
          // If there's an error on the request show a Modal
          console.log("failure")
          commit('setLoading', false)
        }
    },
    // Action for loading items from Google Sheets database
    async loadItems({commit, state}){
      if (!state.loadedItems || state.loadedItems.length === 0) {
        commit('setLoading', true); // Only set loading if loadedItems is empty
      }
        // Use axios to make a get request
        const  payload = {}
        payload.email = auth.currentUser.email
        let response = await axios.get(TABLE_URL+'?action=getData',{params: payload},{
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }})
        // Test response for error states and proceed to filter items on client side
        if(response.status < 400){
            let filteredItems = []
            filteredItems = response.data.items   
            filteredItems.db = response.data.db  
            var db =  response.data.db  
            var noq = response.data.noq
            var noqd = response.data.noqd     
            // Save data loaded from server
            commit('setLoadedItems', filteredItems.reverse())
            commit('setDbName', db)
            commit('setNoqName', noq)
            commit('setNoqdName', noqd)
            commit('setLoading', false)
            console.log(filteredItems)
            console.log(db)
        } else{
          // If there's an error on the request show a Modal
          console.log("failure")
          commit('setLoading', false)
        }
    },
    // Action for loading items from Google Sheets database
    async loadItemsChallenge({commit, state}){
      if (!state.challengeloadedItems || state.challengeloadedItems.length === 0) {
        commit('setLoading', true); // Only set loading if loadedItems is empty
      }
        // Use axios to make a get request
        const  payload = {}
        payload.email = auth.currentUser.email
        let response = await axios.get(TABLE_URL+'?action=getDataChallenge',{params: payload},{
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }})
        // Test response for error states and proceed to filter items on client side
        if(response.status < 400){
            let filteredItems = []
            filteredItems = response.data.items   
            filteredItems.db = response.data.db  
            var db =  response.data.db  
            var noq = response.data.noq
            var noqd = response.data.noqd     
            var noqcorrect = response.data.noqcorrect     
            var noqwrong = response.data.noqwrong     

            // Save data loaded from server
            commit('challengesetLoadedItems', filteredItems)
            commit('challengesetDbName', db)
            commit('challengesetNoqName', noq)
            commit('challengesetNoqdName', noqd)
            commit('challengesetNoqcorrectName', noqcorrect)
            commit('challengesetNoqwrongName', noqwrong)
            commit('setLoading', false)

        } else{
          // If there's an error on the request show a Modal
          console.log("failure")
          commit('setLoading', false)
        }
    },
    // Action for loading raw items from Google Sheets database
    async loadRawQuestions({commit}){
      commit('setLoading', true)  
        // Use axios to make a get request
        const  payload = {}
        payload.email = auth.currentUser.email
        let response = await axios.get(TABLE_URL+'?action=getRawQuestions',{params: payload},{
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }})
        // Test response for error states and proceed to filter items on client side
        if(response.status < 400){
            let filteredItems = []
            filteredItems = response.data.items   
            // Save data loaded from server
            commit('setLoadedRawQuestions', filteredItems)
            commit('setLoading', false)
            console.log(filteredItems)
        } else{
          // If there's an error on the request show a Modal
          console.log("failure")
          commit('setLoading', false)
        }
    },

    // Action for loading items from Google Sheets database
    async loadGabarito({commit}){
      commit('setLoading', true)  
        // Use axios to make a get request
        const  payload = {}
        payload.email = auth.currentUser.email
        let response = await axios.get(TABLE_URL+'?action=getGabarito',{params: payload},{
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }})
        // Test response for error states and proceed to filter items on client side
        if(response.status < 400){
            let filteredItems = []
            filteredItems = response.data.items   
            filteredItems.db = response.data.db  
            var db =  response.data.db       
            // Save data loaded from server
            commit('setLoadedGabarito', filteredItems)
            commit('setDbName', db)
            commit('setLoading', false)
            console.log(filteredItems)
            console.log(db)
        } else{
          // If there's an error on the request show a Modal
          console.log("failure")
          commit('setLoading', false)
        }
    },
    
    // Action for loading items from Google Sheets database
    async loadResults({commit}, payload){
      commit('setLoading', true)  
      payload.email = auth.currentUser.email
        // Use axios to make a get request
        let response = await axios.get(TABLE_URL+'?action=getResults',{params: payload},{
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }})
        // Test response for error states and proceed to filter items on client side
        if(response.status < 400){
          let filteredItems = []
          // If email search is needed
          if(payload.search == true){
            // console.log(response.data);
            // All the data filtering per email from what has been received from the server
            response.data.items.forEach(element => {
              element.date = new Date(element.date)
            })
            filteredItems =  response.data.items.filter(x => {
              return x.email == auth.currentUser.email
            })
          } else{
            // If search not needed display everything
            filteredItems = response.data.items
          }
            // Save data loaded from server
            commit('setLoadedResults', filteredItems.reverse())
            //console.log(filteredItems)
            commit('setLoading', false)
        } else{
          // If there's an error on the request show a Modal
          console.log("failure")
          commit('setLoading', false)
        }
    },  

    async postItems({commit}, payload){
      //commit('setLoading', LOADING_COLOR)
      // Add email and action to payload
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='editResults'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },
    async postItemsChallenge({commit}, payload){
      //commit('setLoading', LOADING_COLOR)
      // Add email and action to payload
      console.log("payloaaddd")
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='editResultsChallenge'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },

    async saveEditedItem({commit}, payload){
      //commit('setLoading', LOADING_COLOR)
      payload.admin = this.state.admin
      // Add email and action to payload
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='editQuestions'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },

    async deleteEditedItem({commit}, payload){
      //commit('setLoading', LOADING_COLOR)
      payload.admin = this.state.admin
      // Add email and action to payload
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='deleteQuestions'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },

    async saveQuestionReset({commit}){
      const  payload = {}
      //commit('setLoading', LOADING_COLOR)
      //console.log(this.state.admin)
      payload.admin = this.state.admin
      // Add email and action to payload
      payload.email = auth.currentUser.email
      console.log(payload)
      payload.action='resetQuestions'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },

    async createItem({commit}, payload){
      //commit('setLoading', LOADING_COLOR)
      payload.admin = this.state.admin
      // Add email and action to payload
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='createQuestions'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },

    async postFlashcards({commit}, payload){
      //commit('setLoading', LOADING_COLOR)
      // Add email and action to payload
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='editResultsFlashcards'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },

    async postSuggestionFlashcards ({commit}, payload){
      // Add email and action to payload
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='editFeedback'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL_FLASHCARDS,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },
    

    async postSuggestion ({commit}, payload){
      // Add email and action to payload
      console.log(payload)
      payload.email = auth.currentUser.email
      payload.action='editFeedback'
      // Run network status plugin
      //let network = await Network.getStatus()

      axios.get(TABLE_URL,{params: payload},{
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }})
      .then(async (response) => {
        // Check return Status code and display a Modal if ok
        if(response.status < 400){
          await console.log("salvo com sucesso")
          commit('setLoading', false)
        } else{
          // If there's an request error show a Modal accordingly
          await console.log("erro ao salvar")
          commit('setLoading', false)
        }
      })
    },




  },
  modules: {
    // módulos adicionais aqui
  },
});